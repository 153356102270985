import React, { useEffect, useRef, useState, useMemo, type Ref, forwardRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import Tooltip from '@atlaskit/tooltip';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import { useDimensions } from '../../../controllers/dimensions';
import type { TitleInnerProps } from './types';

const TitleInner = forwardRef(
	(
		{ title, titleUrl, forgeEnvironment, forgeEnvironmentKey }: TitleInnerProps,
		ref: Ref<HTMLDivElement>,
	) => {
		const displayTitle = forgeEnvironment ? (
			<ExtensionTitle
				extensionEnvType={forgeEnvironment}
				extensionEnvKey={forgeEnvironmentKey}
				extensionName={title}
			/>
		) : (
			title
		);

		return (
			<Heading
				size="small"
				as="h2"
				testId="dashboard-internal-common.ui.gadget.title-readonly.title-inner"
			>
				<HeaderTextWrapper ref={ref}>
					{titleUrl !== undefined ? <Link href={titleUrl}>{displayTitle}</Link> : displayTitle}
				</HeaderTextWrapper>
			</Heading>
		);
	},
);

export const TitleReadOnly = ({
	title,
	titleUrl,
	forgeEnvironment,
	forgeEnvironmentKey,
}: TitleInnerProps) => {
	const dimensions = useDimensions();
	const ref = useRef<HTMLDivElement>(null);
	const [showTooltip, setShowTooltip] = useState(false);
	useEffect(() => {
		if (ref.current == null) {
			return;
		}

		const { offsetWidth, scrollWidth } = ref.current;
		const shouldShowTooltip = offsetWidth < scrollWidth;

		if (showTooltip !== shouldShowTooltip) {
			setShowTooltip(shouldShowTooltip);
		}
	}, [dimensions, showTooltip]);

	const tooltipContent = useMemo(() => {
		if (showTooltip) {
			return forgeEnvironment ? `${title} (${forgeEnvironment})` : title;
		}
		return null;
	}, [showTooltip, forgeEnvironment, title]);

	return (
		<TooltipWrapper>
			<Tooltip content={tooltipContent} position="top">
				<TitleInner
					title={title}
					ref={ref}
					titleUrl={titleUrl}
					forgeEnvironment={forgeEnvironment}
					forgeEnvironmentKey={forgeEnvironmentKey}
				/>
			</Tooltip>
		</TooltipWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipWrapper = styled.div({
	minWidth: 0,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderTextWrapper = styled.div({
	marginTop: 0 /* padding fix for monolith header styling */,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
